<template>
  <div
    class="activity-extra-sales-modal"
    v-if="activity && isAllowed"
  >
    <b-modal
      dialog-class="modal-xl"
      :id="getId()"
      cancel-title="Annuler"
      :ok-disabled="isDisabled()"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Ok"
      @shown="init()"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-plus-circle"></i> Réduction / Supplément {{ activity.name }}</b>
      </template>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="errors.length" class="error-text">
        <i class="fa fa-error"></i> Des erreurs se sont produites
        <ul>
          <li v-for="error of errors" :key="error.id">
            {{ error.text }}
          </li>
        </ul>
      </div>
      <div v-else-if="!isLoading(loadingName)">
        <div><b>Ajouter une vente pour chaque inscrit</b></div>
        <b-row>
          <b-col>
            <b-row class="">
              <b-col>
                <b-form-group
                  id="type-group"
                  label="Type"
                  label-for="type"
                  description=""
                >
                  <b-select
                    id="type"
                    v-model="choice"
                  >
                    <b-select-option v-for="choice of choices" :key="choice.id" :value="choice">
                      {{ choice.name }}
                    </b-select-option>
                  </b-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group :label="choice ? choice.name : '-'" label-for="showPercent">
                  <b-form-checkbox v-model="showPercent">
                    en %
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col v-if="showPercent">
                <b-form-group
                  id="price-group"
                  label="Pourcentage"
                  label-for="percent"
                  description="Pourcentage du prix de base"
                >
                  <decimal-input
                    id="percent"
                    v-model="percent"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
              <b-col v-else>
                <b-form-group
                  id="price-group"
                  label="Montant"
                  label-for="price"
                  description="en euros"
                >
                  <decimal-input
                    id="price"
                    v-model="price"
                  >
                  </decimal-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="comments-group"
                  label="Commentaires"
                  label-for="comments"
                  description="Libellé sur la facture. Si vide, Remboursement/Supplément et nom de l'activité"
                >
                  <b-form-input id="comments" v-model="comments"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div class="small-text">
          <x-table
            id="extra-inscriptions"
            :columns="columns"
            :items="items"
            :show-counter="true"
            verbose-name="Inscription"
            @selectionChanged="onSelectionChanged($event)"
          ></x-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif.vue'
import DecimalInput from '@/components/Controls/DecimalInput'
import XTable from '@/components/Controls/Table/Table.vue'
import { BackendMixin } from '@/mixins/backend'
import { makeChoice } from '@/types/base'
import { BackendApi } from '@/utils/http'
import { currency } from '@/filters/texts'
import { existsIn } from '@/utils/arrays'

const EXTRA = 1
const REFUND = 2

export default {
  name: 'activity-extra-sales-modal',
  mixins: [BackendMixin],
  props: {
    activity: Object,
    inscriptions: Array,
    youthHome: [Number, String],
    id: String,
  },
  components: {
    LoadingGif,
    XTable,
    DecimalInput,
  },
  data() {
    return {
      loadingName: 'activity-extra-sales-modal',
      choice: null,
      price: '',
      percent: '',
      comments: '',
      errors: [],
      commentsTouched: false,
      selection: [],
      selectionIds: [],
      showPercent: false,
      inscriptionItems: [],
    }
  },
  computed: {
    isAllowed() {
      if (this.youthHome) {
        return this.hasAllPerms(['youth.add_youthactivityinscription', 'payments.add_sale'])
      } else {
        return this.hasAllPerms(['activities.add_coreactivityinscription', 'payments.add_sale'])
      }
    },
    columns() {
      return [
        { selector: true, 'name': 'selector', maxWidth: '20px', },
        {
          name: 'name',
          label: 'Nom',
        },
        {
          name: 'basePrice',
          label: 'Prix de base',
          currency: 'true',
        },
        {
          name: 'price',
          label: 'Prix avant',
          currency: 'true',
        },
        {
          name: 'extraPrice',
          label: this.choice ? this.choice.name : '',
          contentCallback: (col, item) => {
            const extra = this.getExtraPrice(item.elt)
            return extra ? currency(extra) : ''
          },
        },
        {
          name: 'priceAfter',
          label: 'Prix après',
          contentCallback: (col, item) => {
            const extra = this.getExtraPrice(item.elt)
            return extra ? currency(this.getPriceAfter(item.elt)) : ''
          },
        },
        {
          name: 'trying',
          label: 'Essai',
        }
      ]
    },
    choices() {
      return [
        makeChoice({ id: EXTRA, name: 'Supplément', }),
        makeChoice({ id: REFUND, name: 'Réduction', })
      ]
    },
    items() {
      return this.inscriptionItems.filter(
        elt => !elt.waiting && !elt.cancelled
      ).map(
        elt => {
          return {
            id: elt.id,
            name: elt.individual.lastAndFirstName(),
            trying: elt.trying ? 'Oui' : '',
            price: elt.getSoldPrice(),
            priceAfter: 1,
            extraPrice: 1,
            entity: elt.entity,
            basePrice: elt.price,
            elt: elt,
          }
        }
      )
    },
    isRefund() {
      return this.choice && this.choice.id === REFUND
    },
  },
  watch: {
    activity: function() {},
    price: function() {},
    percent: function() {},
    showPercent: function() {},
  },
  methods: {
    ...mapActions(['addSuccess']),
    ...mapMutations(['startLoading']),
    getId() {
      return this.id || 'bv-modal-activity-extra-sales-modal'
    },
    init() {
      this.inscriptionItems = this.inscriptions
      this.choice = null
      this.percent = ''
      this.price = ''
      this.comments = ''
    },
    isDisabled() {
      return (
        (this.choice === null) ||
        (isNaN(+this.price)) ||
        (!this.showPercent && (+this.price <= 0)) ||
        (this.showPercent && (+this.percent <= 0)) ||
        (this.selection.length === 0) ||
        this.isLoading(this.loadingName)
      )
    },
    async onSave() {
      this.errors = []
      let errors = []
      this.startLoading(this.loadingName)
      for (const item of this.selection) {
        try {
          let url = '/api/entity/' + item.entity.id + '/inscriptions/'
          if (this.youthHome) {
            url = '/api/entity/' + item.entity.id + '/youth/activity-inscriptions/'
          }
          url += item.id + '/add_extra_sale/'
          const backendApi = new BackendApi('post', url)
          let data = {
            price: this.getExtraPrice(item.elt),
            comments: this.comments,
          }
          await backendApi.callApi(data)
        } catch (err) {
          errors.push(
            { id: item.id, text: item.name + ': ' + this.getErrorText(err), }
          )
        }
      }
      this.endLoading(this.loadingName)
      if (errors.length) {
        this.errors = errors
      } else {
        if (this.isRefund) {
          await this.addSuccess('La réduction a été ajoutée')
        } else {
          await this.addSuccess('Le supplément a été ajouté')
        }
        this.$bvModal.hide(this.getId())
      }
      this.$emit('done')
    },
    getExtraPrice(elt) {
      let result = 0
      if (existsIn([elt.id], this.selectionIds)) {
        let diff = 0
        if (this.showPercent) {
          if (this.percent && elt.price) {
            diff = (+this.percent) * elt.price / 100
            diff = Math.round(100 * diff) / 100
          }
        } else {
          diff = +(this.price || 0)
        }
        if (this.choice) {
          let price = diff
          result += (this.isRefund) ? -price : price
        }
      }
      return result
    },
    getPriceAfter(elt) {
      return elt.getSoldPrice() + this.getExtraPrice(elt)
    },
    // getPercentage() {
    //   if (this.price) {
    //     const value = 100 * (+this.price) / this.inscription.getPrice()
    //     return '' + (Math.round(100 * value) / 100)
    //   }
    //   return ''
    // },
    // percentageAmountChanged(event) {
    //   const newPrice = this.inscription.getPrice() * (+event.value) / 100
    //   this.price = Math.round(newPrice * 100) / 100
    // },
    onSelectionChanged(event) {
      this.selection = event.items
      this.selectionIds = this.selection.map(elt => elt.id)
    },
  },
  mounted() {
  },
}
</script>
<style lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    padding: 3px;
  }
</style>
