<template>
  <span>
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      cancel-title="Non"
      @ok.prevent="onSave"
      ok-variant="primary"
      ok-title="Oui, Confirmer l'annulation"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-close"></i> Annuler toutes les inscription à une activité</b>
      </template>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
      <div class="confirm-text">
        Souhaitez-vous annuler toutes les inscriptions de
        l'activité {{ activity.name }} {{ activity.schoolYear.name }} ?
      </div>
      <b-row>
        <b-col>
          <b-row class="">
            <b-col>
              <b-form-group
                id="refund-group"
                label-for="refund"
                description="En cochant la case, les ventes associées seront annulées ou remboursées si déjà facturées"
              >
              <b-checkbox
                  id="refund"
                  v-model="refund"
                  :value="true"
                  :unchecked-value="false"
                >
                  Rembourser les inscriptions
                </b-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-modal>
  </span>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>
import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'cancel-activity-inscriptions-modal',
  mixins: [BackendMixin],
  props: {
    activity: Object,
    modalId: String,
  },
  components: {
  },
  data() {
    return {
      refund: true,
      errorText: '',
    }
  },
  computed: {
  },
  watch: {
    activity: function(newValue, oldValue) {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    async onSave() {
      this.errorText = ''
      try {
        let url = '/api/activities/cancel-activity-inscriptions/' + this.activity.id + '/'
        const backendApi = new BackendApi('post', url)
        let data = {
          refund: this.refund,
        }
        await backendApi.callApi(data)
        this.$bvModal.hide(this.modalId)
        if (this.refund) {
          await this.addSuccess('Les inscriptions ont été annulées et remboursées')
        } else {
          await this.addSuccess('Les inscriptions ont été annulée sans remboursement')
        }
        this.$emit('done')
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
  mounted() {
  },
}
</script>
<style scoped lang="less">
  .error-text {
    padding: 20px;
    color: #cc3700;
    background: #e0e0e0;
  }
  .confirm-text {
    font-weight: bold;
  }
</style>
